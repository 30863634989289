import React from 'react';
import styled from 'styled-components';

import faris from './images/faris.svg';
import ospca from './images/ospca.svg';
import ymca from './images/ymca.svg';
import blueDiamond from './images/blue-diamond.svg';
import avenue from './images/avenue.svg';
import medipharm from './images/medipharm.svg';
import peaks from './images/peaks.svg';
import flyingMonkeys from './images/flying-monkeys.svg';
import littleBuddha from './images/little-buddha.svg';
import peaceful from './images/peaceful.svg';
import cmha from './images/cmha.svg';
import tangle from './images/tangle.svg';

const StyledSection = styled.section`
	background-color: ${(p) => p.theme.color.ghost};
	.inner {
		max-width: 64rem;
		padding: 2rem 1rem;
		margin: 0 auto;
	}
	h2 {
		font-size: 1.25rem;
		font-family: ${(p) => p.theme.font.display};
		color: ${(p) => p.theme.color.secondary};
		text-align: center;
		margin-bottom: 2rem;
	}
	.logos {
		.row {
			display: flex;
			flex-wrap: wrap;
			& > div {
				flex: 0 0 16.6%;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 150px;
				@media ${(p) => p.theme.device.mobile} {
					flex: 0 0 33.3%;
					height: 100px;
				}
				@media ${(p) => p.theme.device.tablet} {
					flex: 0 0 33.3%;
				}
				img {
					max-height: 50px;
					max-width: 80%;
				}
			}
		}
	}
`;

export default () => {
	return (
		<StyledSection>
			<div className="inner">
				<h2>GREAT WORK REQUIRES GREAT CLIENTS. LUCKY FOR US:</h2>
				<div className="logos">
					<div className="row">
						<div>
							<img src={faris} alt="" />
						</div>
						<div>
							<img src={ospca} alt="" />
						</div>
						<div>
							<img src={ymca} alt="" />
						</div>
						<div>
							<img src={blueDiamond} alt="" />
						</div>
						<div>
							<img src={avenue} alt="" />
						</div>
						<div>
							<img src={medipharm} alt="" />
						</div>
						<div>
							<img src={peaks} alt="" />
						</div>
						<div>
							<img src={flyingMonkeys} alt="" />
						</div>
						<div>
							<img src={littleBuddha} alt="" />
						</div>
						<div>
							<img src={peaceful} alt="" />
						</div>
						<div>
							<img src={cmha} alt="" />
						</div>
						<div>
							<img src={tangle} alt="" />
						</div>
					</div>
				</div>
			</div>
		</StyledSection>
	);
};
