import React from 'react';
import { Link } from 'gatsby';

import Page from '../components/core/page';
import Hero from '../components/pages/home/hero';
import Opener from '../components/common/opener';
import Closer from '../components/common/closer';
import SketchBanner from '../components/pages/home/sketch-banner';
import LogoSection from '../components/pages/home/logo-section';

import hand from '../images/hand.svg';

export default () => (
	<Page
		title="Home"
		desc="Established by 5 marketing & ad agency veterans, 5fold elevates creativity, technology and customer service to help each of our clients become a success story."
	>
		<Hero>
			<span>A creative agency for </span>
			<span>businesses and brands who </span>
			<span>
				<strong>strive to thrive</strong>
			</span>
		</Hero>
		<Opener title="about us" subtitle="SMALL TOWN. WORLD CLASS.">
			<div>
				<h2>
					Elevating creativity, technology and customer service to help each of
					our clients become a success story.
				</h2>
				<p>
					Established by five marketing and advertising agency veterans with a
					track record for serving both local businesses and national brands,
					5fold is a creative agency relentlessly driven to help our clients
					reach their goals and their full potential.
				</p>
				<Link to="/services">
					<button>OUR SERVICES</button>
				</Link>
				<Link to="/work">
					<button>OUR WORK</button>
				</Link>
			</div>
			<div className="hand">
				<img src={hand} alt="" />
			</div>
		</Opener>
		<SketchBanner />
		<LogoSection />
		<Closer>
			<h2>
				You care about your business.
				<br /> We can make customers care about it.
				<br />
				<Link to="/contact">Let's talk.</Link>
			</h2>
		</Closer>
	</Page>
);
