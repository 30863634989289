import React from 'react';
import styled from 'styled-components';

import ScrollParallax from '../../../common/scroll-paralax';

import sketch from './images/sketches.svg';

const BannerStyled = styled.section`
	background-color: ${(p) => p.theme.color.primary};
	overflow-x: hidden;
	padding: 1rem;
	img {
		max-width: 118%;
		width: 118%;
	}
	@media ${(p) => p.theme.device.tablet} {
		padding: 0.5rem 0;
		img {
			max-width: 225%;
			width: 225%;
		}
	}
`;

export default () => {
	return (
		<BannerStyled>
			<ScrollParallax imgSrc={sketch} imgAlt="sketches" min={0} max={-10} />
		</BannerStyled>
	);
};
