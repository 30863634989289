import styled from 'styled-components';
import heroImg from '../images/hero-opt.jpg';

export default styled.div`
	background-image: url(${heroImg});
	background-size: cover;
	background-position: center center;
	height: calc(100vh + 1px);
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: ${(p) => p.theme.color.lightest};
	h1 {
		font-size: 3rem;
		display: flex;
		flex-direction: column;
		span {
			align-self: center;
			background-color: rgba(0, 0, 0, 0.75);
			margin-bottom: 1rem;
			padding: 0.5rem 2rem;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	@media ${(p) => p.theme.device.mobile} {
		height: 400px;
		h1 {
			font-size: 1.25rem;
		}
	}
`;
