import React from 'react';

import HeroStyled from './style';
import FadeInOnVisibility from '../../../common/fade-in-on-visibility';

export default ({ children }) => {
	return (
		<HeroStyled>
			<FadeInOnVisibility>
				<h1>{children}</h1>
			</FadeInOnVisibility>
		</HeroStyled>
	);
};
